<template>
    <div class="message" v-bind:class="message.messageState.class" :data-cy="`flash-${message.messageState.class}`">
        <div class="message__content">
            <header class="message__header">{{ message.header }}</header>
            <span class="message__text">{{ message.description }}</span>
        </div>
        <span @click="removeMessage(message)" class="close-button topright">&times;</span>
    </div>
</template>

<script>
import store from "@/store/store";
import { mutationTypes } from "@/store/FlashMessages/types";

export default {
    name: "LaFlashMessage",
    props: {
        message: {
            header: String,
            description: String,
            messageState: Object,
        },
    },
    setup() {
        const removeMessage = (m) => {
            store.commit(mutationTypes.removeMessageFromQueue, {
                id: m.id,
            });
        };
        return { removeMessage };
    },
    mounted() {
        // po 3.5 sec kazda message zmizi
        setTimeout(() => this.removeMessage(this.message), 3500);
    },
};
</script>

<style lang="scss" scoped>
.message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row reverse;
    margin: 0.5rem 0;
    color: white;
    border-radius: 4px;
    padding: 0.5rem;
    font-size: 0.9rem;
    &__content {
        padding: 0 1rem;
    }
    &__header {
        font-weight: bold;
    }
}

.info-message {
    background-color: $transparent-blue;
}

.warn-message {
    background-color: $transparent-red;
}

.success-message {
    background-color: $transparent-green;
}
.close-button {
    font-size: 2rem;
    border: none;
    display: inline-block;
    margin-right: 1rem;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    background-color: none;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
}
</style>
